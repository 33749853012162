// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-dictionary-ios-app-js": () => import("./../../../src/pages/project/dictionary-ios-app.js" /* webpackChunkName: "component---src-pages-project-dictionary-ios-app-js" */),
  "component---src-pages-project-hulu-devx-js": () => import("./../../../src/pages/project/hulu-devx.js" /* webpackChunkName: "component---src-pages-project-hulu-devx-js" */),
  "component---src-pages-project-thesaurus-grammar-coach-js": () => import("./../../../src/pages/project/thesaurus-grammar-coach.js" /* webpackChunkName: "component---src-pages-project-thesaurus-grammar-coach-js" */)
}

